import React from 'react';
import { makeStyles } from '@mui/styles';
import LogoNovo from '../resources/images/cropped-logo.png';

const useStyles = makeStyles(() => ({
  image: {
    alignSelf: 'center',
    maxWidth: '280px',
    maxHeight: '140px',
    width: 'auto',
    height: 'auto',
  },
}));

const LogoImage = () => {
  const classes = useStyles();

  return <img className={classes.image} src={LogoNovo} alt="logo" />;
};

export default LogoImage;
